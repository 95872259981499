import PropTypes from 'prop-types';

// constants
import { textValues } from './constants';

// components
import GenericButton from '@/components/widgets/genericButton';
import ImageComponent from '@/components/widgets/image';

// Material
import { School } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// Styles
import TooltipTypography from '@/components/widgets/tooltipTypography';
import useStyles from './style';

export default function ProgramCard(props) {
	const { classes } = useStyles();

	return (
		<Box className={classes.programCard}>
			<Box className={classes.titleSection}>
				<Box className={classes.imageWrap}>
					{props.logo ? (
						<ImageComponent
							src={props.logo}
							alt={props.name}
							className={classes.image}
							layout="fill"
							objectFit="contain"
						/>
					) : (
						<School className={classes.icon} />
					)}
				</Box>
				<Box>
					<Typography fontWeight="fontWeightMedium" className={classes.title}>
						{props.name}
					</Typography>
					<Typography variant="subtitle2">{props.levelAndType}</Typography>
				</Box>
			</Box>
			<Box className={classes.detailSection}>
				<Box className={classes.detail}>
					<Typography className={classes.label}>
						{textValues.courseFees}
					</Typography>
					<Typography className={classes.detailValue}>
						{props.tuitionFee}
					</Typography>
				</Box>
				<Box className={classes.detail}>
					<Typography className={classes.label}>
						{textValues.intakes}
					</Typography>
					<TooltipTypography
						text={props.intakeMonths.join(' / ')}
						extraDetails={props.intakeMonths}
						className={classes.detailValue}
						maxTextLength={15}
					/>
				</Box>
				<Box className={classes.detail}>
					<Typography className={classes.label}>
						{textValues.applicationFee}
					</Typography>
					<Typography className={classes.detailValue}>
						{props.applicationFee}
					</Typography>
				</Box>
				<Box className={classes.detail}>
					<Typography className={classes.label}>
						{textValues.duration}
					</Typography>
					<Typography className={classes.detailValue}>
						{props.length}
					</Typography>
				</Box>
			</Box>
			<Box className={classes.buttonWrap}>
				<GenericButton
					label={props.buttonLabel}
					handleClick={() => props.handleButtonClick(props.link)}
					variant="contained"
					className={classes.button}
					fullWidth
				/>
			</Box>
		</Box>
	);
}

ProgramCard.propTypes = {
	name: PropTypes.string.isRequired,
	logo: PropTypes.string,
	levelAndType: PropTypes.string.isRequired,
	tuitionFee: PropTypes.string.isRequired,
	intakeMonths: PropTypes.array,
	length: PropTypes.string.isRequired,
	applicationFee: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	handleButtonClick: PropTypes.func.isRequired,
	buttonLabel: PropTypes.string.isRequired,
};

ProgramCard.defaultProps = {
	intakeMonths: [],
};
