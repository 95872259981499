import PropTypes from 'prop-types';

// components
import ImageComponent from '@/components/widgets/image';
import TooltipTypography from '@/components/widgets/tooltipTypography';

// material
import { School } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function UniversityCard({
	image,
	logo,
	name,
	ranking,
	intakeMonths,
	courseCount,
	fullWidth,
	fullHeight,
}) {
	const { classes, cx } = useStyles();

	const renderRanking = () => {
		if (ranking.source) {
			return (
				<Box className={classes.stat}>
					<Typography
						component="span"
						className={cx(classes.statText, classes.addSpaceAfter)}
					>
						{ranking.rank}
					</Typography>
					<Typography component="span" className={classes.source}>
						{ranking.source}
					</Typography>
				</Box>
			);
		}

		return <Typography className={classes.statText}>{ranking.rank}</Typography>;
	};

	return (
		<Box
			className={cx(
				classes.universityCard,
				fullWidth ? classes.fullWidth : '',
				fullHeight ? classes.fullHeight : ''
			)}
		>
			<Box className={classes.imageWrap}>
				{image.url && (
					<ImageComponent
						src={image.url}
						alt={image.alternativeText}
						quality="20%"
						layout="fill"
						objectFit="fill"
					/>
				)}
			</Box>
			<Box className={classes.bodySection}>
				<Box className={classes.titleSection}>
					<Box className={classes.logoWrap}>
						{logo.url ? (
							<ImageComponent
								src={logo.url}
								alt={logo.alternativeText}
								className={classes.logo}
								layout="fill"
								quality="20%"
								objectFit="contain"
							/>
						) : (
							<School className={classes.icon} />
						)}
					</Box>
					<Typography component="h6" variant={'h6'} className={classes.title}>
						{name}
					</Typography>
				</Box>
				<Box className={classes.statWrap}>
					{ranking && renderRanking()}
					{intakeMonths && (
						<Box className={classes.stat}>
							<TooltipTypography
								text={intakeMonths}
								extraDetails={intakeMonths.split('/')}
								className={classes.statText}
								maxTextLength={11}
								component="span"
							/>
						</Box>
					)}
					{courseCount && (
						<Box className={classes.stat}>
							<Typography component="span" className={classes.statText}>
								{courseCount}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
}

UniversityCard.propTypes = {
	name: PropTypes.string.isRequired,
	image: PropTypes.object,
	logo: PropTypes.object,
	ranking: PropTypes.object,
	intakeMonths: PropTypes.string,
	courseCount: PropTypes.string,
	fullWidth: PropTypes.bool,
	fullHeight: PropTypes.bool,
};
