import Link from 'next/link';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { textValues } from './constants';
import uiSchema from './uiSchema';

// components
import SearchFieldGroup from '@/components/widgets/searchFieldGroup';
import UniversityCard from '@/modules/unversityPage/universityCard';
import Loader from '@/modules/unversityPage/universityCard/loader';

// material
import { Box, TablePagination, Typography } from '@mui/material';

// utils
import getControlSchema from './schema';

// styles
import useStyles from './style';

export default function UniversityGroupSection(props) {
	const { classes } = useStyles();

	const isDesktop = useSelector((state) => state.common.isDesktop);

	const controlSchema = React.useMemo(
		() => getControlSchema(props.searchDropdownFilterEnums),
		[props.searchDropdownFilterEnums]
	);

	const dropdownOptions = props.searchDropdownFilterEnums.map((country) => ({
		label: country,
		value: country,
	}));

	const paginationClasses = {
		classes: {
			root: classes.paginationRoot,
			toolbar: classes.paginationToolbar,
			selectLabel: classes.paginationSelectLabel,
			displayedRows: classes.paginationDisplayedRows,
			actions: classes.paginationActions,
		},
		SelectProps: {
			className: classes.paginationSelectRoot,
		},
	};

	return (
		<Box>
			<Box className={classes.searchWrap}>
				<SearchFieldGroup
					useIconOnInput={true}
					inputValue={props.searchText}
					inputPlaceholder={textValues.searchUniversities}
					handleInputChange={props.handleSearchTextChange}
					useDropdown={true}
					dropdownValue={props.dropdownValue}
					dropdownOptions={dropdownOptions}
					handleDropdownChange={props.handleDropdownChange}
					useFilters={true}
					filterData={props.filters}
					filterApplied={props.filterApplied}
					handleApplyFilter={props.handleApplyFilter}
					handleClearFilter={props.handleClearFilter}
					filterUiSchema={uiSchema}
					filterControlSchema={controlSchema}
					handleFilterChange={props.handleFilterChange}
					modalOpen={props.modalOpen}
					toggleModalOpen={props.toggleModalOpen}
				/>
			</Box>

			<Box className={classes.cardListWrap}>
				{props.loading ? (
					Array.from({ length: 15 }, (_, index) => (
						<Box className={classes.cardWrap} key={index}>
							<Box className={classes.cardWrapLink}>
								<Loader />
							</Box>
						</Box>
					))
				) : props.cardList.length === 0 ? (
					<Typography variant="h6" className={classes.noDataText}>
						{textValues.noDataText}
					</Typography>
				) : (
					props.cardList.map((university, index) => (
						<Box className={classes.cardWrap} key={index}>
							<Link href={university.link}>
								<a className={classes.cardWrapLink}>
									<UniversityCard
										name={university.name}
										logo={university.logo}
										rank={university.rank}
										ranking={university.ranking}
										image={university.image}
										intakeMonths={university.intakeMonths}
										courseCount={university.courseCount}
										fullHeight={true}
										fullWidth={true}
									/>
								</a>
							</Link>
						</Box>
					))
				)}
			</Box>
			<TablePagination
				component="div"
				count={props.total}
				page={props.page - 1}
				onPageChange={props.handleChangePage}
				rowsPerPage={props.pageSize}
				rowsPerPageOptions={props.rowsPerPageOptions}
				onRowsPerPageChange={props.handleChangeRowsPerPage}
				labelRowsPerPage={textValues.perPageLabel}
				{...(isDesktop ? {} : paginationClasses)}
			/>
		</Box>
	);
}

UniversityGroupSection.propTypes = {
	// func
	loading: PropTypes.bool,

	// data
	searchQueryValues: PropTypes.object,
	cardList: PropTypes.array,

	// search text
	searchText: PropTypes.string,
	handleSearchTextChange: PropTypes.func.isRequired,

	// dropdown
	dropdownValue: PropTypes.string,
	handleDropdownChange: PropTypes.func.isRequired,
	searchDropdownFilterEnums: PropTypes.array,

	// filters
	filterApplied: PropTypes.bool,
	filters: PropTypes.object,
	handleApplyFilter: PropTypes.func.isRequired,
	handleClearFilter: PropTypes.func.isRequired,
	handleFilterChange: PropTypes.func.isRequired,

	// modal
	modalOpen: PropTypes.bool,
	toggleModalOpen: PropTypes.func.isRequired,

	// pagination
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	rowsPerPageOptions: PropTypes.array.isRequired,
	handleChangePage: PropTypes.func.isRequired,
	handleChangeRowsPerPage: PropTypes.func.isRequired,
};

UniversityGroupSection.defaultProps = {
	loading: false,
	modalOpen: false,
	filterApplied: false,
	cardList: [],
	searchDropdownFilterEnums: [],
	filters: {},
	searchQueryValues: {},
};
