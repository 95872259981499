import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSectionWrap: {
		marginBottom: theme.spacing(2),
	},
	searchWrap: {
		marginBottom: theme.spacing(2),
	},
	programCardListWrap: {
		display: 'flex',
		gap: theme.spacing(4, 5),
		flexWrap: 'wrap',
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			gap: theme.spacing(2),
		},
	},
	noProgramText: {
		width: '100%',
		textAlign: 'center',
	},
	programCardWrap: {
		flexBasis: 'calc(33% - 24px)',
		[theme.breakpoints.down('md')]: {
			flexBasis: 'calc(50% - 16px)',
		},
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%',
		},
	},
	paginationRoot: {
		overflow: 'hidden',
	},
	paginationToolbar: {
		flexWrap: 'wrap',
		justifyContent: 'center',
		padding: 0,
	},
	paginationSelectLabel: {
		textAlign: 'right',
		margin: '0px',
		fontSize: '14px',
	},
	paginationSelectRoot: {
		margin: '0px 8px',
	},
	paginationDisplayedRows: {
		margin: '0px',
		textAlign: 'right',
		fontSize: '14px',
	},
	paginationActions: {
		marginLeft: '0px!important',
	},
}));

export default useStyles;
