// Material
import { makeStyles } from 'tss-react/mui';

// Styles
const useStyles = makeStyles()((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.common.black,

		padding: theme.spacing(1, 1.5),
		width: 'max-content',
		boxShadow: 'rgb(170 170 170) 1px 1px 10px -1px',
		borderRadius: '5px',
	},
	tooltipArrow: {
		color: theme.palette.common.white,
	},
	eyeIcon: {
		cursor: 'pointer',
		fontSize: '14px',
		verticalAlign: 'baseline',
	},
	extraDetail: {
		fontWeight: theme.typography.fontWeightMedium,
		display: 'block',
	},
	primaryColor: {
		color: theme.palette.primary.main,
	},
	blackColor: {
		color: theme.palette.common.black,
	},
}));

export default useStyles;
