import PropTypes from 'prop-types';

// material
import { InfoOutlined } from '@mui/icons-material';
import { ClickAwayListener, Tooltip, Typography } from '@mui/material';
// style
import useStyles from './style';

export default function TooltipTypography({
	text,
	extraDetails,
	className,
	maxTextLength,
	...typographyProps
}) {
	const { classes, cx } = useStyles();

	const [showTooltip, setShowTooltip] = React.useState(false);

	const toggleShowTooltip = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setShowTooltip(!showTooltip);
	};

	const closeTooltip = () => setShowTooltip(false);

	if (text.length <= maxTextLength || !extraDetails)
		return (
			<Typography className={className} {...typographyProps}>
				{text.length <= maxTextLength
					? text
					: `${text.slice(0, maxTextLength)}...`}
			</Typography>
		);

	return (
		<Typography className={className} {...typographyProps}>
			<>
				{text.length <= maxTextLength
					? text
					: `${text.slice(0, maxTextLength)}...`}
				<ClickAwayListener onClickAway={closeTooltip}>
					<Tooltip
						open={showTooltip}
						placement="right"
						arrow
						classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
						title={extraDetails.map((detail, index) => (
							<Typography
								key={index}
								component="span"
								className={classes.extraDetail}
							>
								{detail}
							</Typography>
						))}
						PopperProps={{ disablePortal: true }}
						disableFocusListener
						disableHoverListener
						disableTouchListener
					>
						<InfoOutlined
							onClick={toggleShowTooltip}
							fontSize="small"
							classes={{
								root: cx(
									classes.eyeIcon,
									showTooltip ? classes.primaryColor : classes.blackColor
								),
							}}
						/>
					</Tooltip>
				</ClickAwayListener>
			</>
		</Typography>
	);
}

TooltipTypography.propTypes = {
	text: PropTypes.string.isRequired,
	extraDetails: PropTypes.array,
	className: PropTypes.string,
	maxTextLength: PropTypes.number,
};

TooltipTypography.defaultProps = {
	maxTextLength: 8,
};
