import get from 'lodash/get';
import { rowsPerPageOptions, themes } from './constants';

const matchAgainstFilter = (program, filters, searchText) => {
	const { program_level_and_type } = filters;
	let isValidProgram = true;

	if (searchText && isValidProgram)
		isValidProgram = program.name.trim().toLowerCase().includes(searchText);

	if (program_level_and_type && isValidProgram)
		isValidProgram = program_level_and_type
			.split(',')
			.includes(program.levelAndType);

	return isValidProgram;
};

export const filterProgram = (programs, filters) => {
	return new Promise((resolve) => {
		const searchText = filters.q && filters.q.trim().toLowerCase();

		const { page, per_page } = filters,
			filteredPrograms = programs.filter((program) =>
				matchAgainstFilter(program, filters, searchText)
			),
			data = filteredPrograms.slice((page - 1) * per_page, page * per_page);

		resolve({ data, meta: { pagination: { total: filteredPrograms.length } } });
	});
};

export const getQueryArray = (filters, searchText, page, pageSize) => {
	let query = [],
		currentValue;

	Object.keys(filters).forEach((field) => {
		currentValue = filters[field];
		if (
			currentValue &&
			(Array.isArray(currentValue) ? currentValue.length > 0 : true)
		)
			query.push({ key: field, value: currentValue });
	});

	if (searchText) query.push({ key: 'q', value: searchText });
	if (page) query.push({ key: 'page', value: page });
	if (pageSize) query.push({ key: 'per_page', value: pageSize });

	return query;
};

export const getInitialFilter = (searchQueryValues, type) => {
	const initialValue = {};

	switch (type) {
		case themes.v2:
			if (get(searchQueryValues, 'program_level_and_type'))
				initialValue.program_level_and_type =
					searchQueryValues.program_level_and_type.split(',');
			break;

		case themes.v3:
			if (get(searchQueryValues, 'country'))
				initialValue.country = searchQueryValues.country;

			if (get(searchQueryValues, 'type'))
				initialValue.type = searchQueryValues.type.split(',');

			if (get(searchQueryValues, 'category'))
				initialValue.category = searchQueryValues.category.split(',');
			break;

		default:
			break;
	}

	return initialValue;
};

export const getInitialPerPage = (searchQueryValues) => {
	const perPage = parseInt(get(searchQueryValues, 'per_page', 0));
	return rowsPerPageOptions.includes(perPage) ? perPage : 15;
};
