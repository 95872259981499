import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	buttonGroupWrap: {
		marginBottom: theme.spacing(3),
		display: 'flex',
		justifyContent: 'center',
	},
	titleSectionWrap: {
		marginBottom: theme.spacing(2),
	},
	programCardListWrap: {
		display: 'flex',
		gap: theme.spacing(4, 5),
		flexWrap: 'wrap',
		[theme.breakpoints.down('md')]: {
			gap: theme.spacing(2),
		},
	},
	programCardWrap: {
		flexBasis: 'calc(33% - 24px)',
		[theme.breakpoints.down('md')]: {
			flexBasis: 'calc(50% - 16px)',
		},
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%',
		},
	},
	linkWrap: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	link: {
		color: theme.palette.primary.main,
	},
	noDataText: {
		margin: '0 auto',
	},
}));

export default useStyles;
