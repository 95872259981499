import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	programCard: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(4),
		padding: theme.spacing(3, 2),
		border: `1px solid ${theme.palette.text.disabled}`,
		borderRadius: '15px',
		height: '100%',
	},
	titleSection: {
		display: 'flex',
		gap: theme.spacing(2),
	},
	title: {
		wordBreak: 'break-word',
	},
	imageWrap: {
		height: '55px',
		minWidth: '55px',
		position: 'relative',
		border: `1px solid ${theme.palette.text.disabled}`,
		borderRadius: '50%',
		overflow: 'hidden',
	},
	icon: {
		padding: '8px',
		height: '50px',
		width: '50px',
	},
	image: {
		padding: '8px !important',
	},
	detailSection: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(2),
	},
	detail: {
		flexBasis: 'calc(50% - 8px)',
	},
	buttonWrap: {
		height: '100%',
		display: 'flex',
		alignItems: 'flex-end',
	},
	button: {
		borderRadius: '33px',
		background:
			'linear-gradient(263.25deg, #F58320 0%, rgba(241, 105, 33, 0.987054) 81.82%, #D75312 109%)',
	},
	label: {
		color: theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightMedium,
	},
	detailValue: {
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

export default useStyles;
