const getControlSchema = (countries) => ({
	type: 'object',
	properties: {
		country: {
			type: 'string',
			enum: countries || ['USA'],
		},
		type: {
			type: 'array',
			items: {
				type: 'string',
				enum: ['Public', 'Private'],
			},
		},
	},
	required: [],
});

export default getControlSchema;
