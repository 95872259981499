import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	universityCard: {
		overflow: 'hidden',
		borderRadius: '18px',
		display: 'inline-flex',
		flexDirection: 'column',
		maxWidth: '386px',
		width: '100%',
		backgroundColor: theme.palette.common.lightGrey,
	},
	imageWrap: {
		position: 'relative',
		width: '100%',
		paddingTop: '72.6%',
		backgroundColor: theme.palette.common.backgroundGrey,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '54%',
		},
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
	},
	bodySection: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	titleSection: {
		display: 'flex',
		gap: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	title: {
		color: theme.palette.primary.main,
		width: '100%',
		wordBreak: 'break-word',
	},
	logoWrap: {
		position: 'relative',
		width: '32px',
		height: '32px',
		borderRadius: '50%',
		overflow: 'hidden',
		border: `1px solid ${theme.palette.text.disabled}`,
		flexShrink: 0,
	},
	logo: {
		padding: '6px!important',
	},
	icon: {
		padding: '6px',
		height: '30px',
		width: '30px',
	},
	statWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(1),

		'& div + div': {
			display: 'flex',
			flexWrap: 'wrap',
			gap: 'inherit',
			'&::before': {
				content: '"|"',
				whiteSpace: 'pre',
			},
		},

		[theme.breakpoints.down(300)]: {
			gap: '4px',
		},
	},
	stat: {
		display: 'flex',
		alignItems: 'center',
		margin: 0,
	},
	statText: {
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.disabled,
		verticalAlign: 'top',
	},
	source: {
		...theme.typography.caption,
		verticalAlign: 'top',
		textAlign: 'center',
		display: 'inline-block',
	},
	fullWidth: {
		maxWidth: '100%!important',
	},
	fullHeight: {
		height: '100%!important',
	},
	addSpaceAfter: {
		'&::after': {
			content: '" "',
			whiteSpace: 'pre',
		},
	},
}));

export default useStyles;
