const uiSchema = {
	type: 'VerticalLayout',
	elements: [
		{
			type: 'Control',
			scope: '#/properties/program_level_and_type',
			label: 'Program Level and Type',
			options: {
				component: 'multiSelect',
			},
		},
	],
};

export default uiSchema;
