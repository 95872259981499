import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSectionWrap: {
		marginBottom: theme.spacing(2),
	},
	searchWrap: {
		marginBottom: theme.spacing(2),
	},
	cardListWrap: {
		margin: '0 auto 24px',
		maxWidth: '1220px',
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(5, 4),
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('lg')]: {
			maxWidth: '804px',
		},
		[theme.breakpoints.down('md')]: {
			maxWidth: '100%',
			gap: theme.spacing(3),
		},
	},
	noDataText: {
		width: '100%',
		textAlign: 'center',
	},
	cardWrap: {
		maxWidth: '386px',
		flexBasis: 'calc(33% - 18px)',
		overflow: 'hidden',
		[theme.breakpoints.down('lg')]: {
			flexBasis: 'calc(50% - 16px)',
		},
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%',
			margin: '0 auto',
		},
	},
	cardWrapLink: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
	},
	paginationRoot: {
		overflow: 'hidden',
	},
	paginationToolbar: {
		flexWrap: 'wrap',
		justifyContent: 'center',
		padding: 0,
	},
	paginationSelectLabel: {
		textAlign: 'right',
		margin: '0px',
		fontSize: '14px',
	},
	paginationSelectRoot: {
		margin: '0px 8px',
	},
	paginationDisplayedRows: {
		margin: '0px',
		textAlign: 'right',
		fontSize: '14px',
	},
	paginationActions: {
		marginLeft: '0px!important',
	},
}));

export default useStyles;
