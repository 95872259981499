import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function Loader({ fullWidth }) {
	const { classes, cx } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	return (
		<Box
			className={cx(classes.universityCard, fullWidth ? classes.fullWidth : '')}
		>
			<Box className={classes.imageWrap}>
				<CircularProgress className={classes.circularProgress} />
			</Box>
			<Box className={classes.bodySection}>
				<Box className={classes.titleSection}>
					<Typography
						component="h6"
						variant={isMobile ? 'h6' : 'h5'}
						className={classes.title}
					>
						<Skeleton width="100%" height={32} />
					</Typography>
				</Box>
				<Box className={classes.statWrap}>
					<Skeleton width="100%" />
				</Box>
			</Box>
		</Box>
	);
}

Loader.propTypes = {
	fullWidth: PropTypes.bool,
};
