import { levelAndType } from '@/utils/constants/program';

const controlSchema = {
	type: 'object',
	properties: {
		program_level_and_type: {
			type: 'array',
			items: {
				type: 'string',
				enum: Object.values(levelAndType),
			},
		},
	},
	required: [],
};

export default controlSchema;
