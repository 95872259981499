export const themes = {
	v1: 'programWithToggle',
	v2: 'programWithFilter',
	v3: 'unviersityWithFilter',
};

export const textValues = {
	scrollId: 'data-list-scroll-anchor',
};

export const rowsPerPageOptions = [3, 15, 30, 45];
