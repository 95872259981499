import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { textValues } from './constants';
import controlSchema from './schema';
import uiSchema from './uiSchema';

// components
import TitleSection from '@/components/shared/titleSection';
import SearchFieldGroup from '@/components/widgets/searchFieldGroup';
import ProgramCard from '@/modules/unversityPage/programCard';

// material
import { Box, TablePagination, Typography } from '@mui/material';

// utils
import { goToExternalRoute } from '@/store/actions';
import useUserModal from '@/utils/hooks/use-login-modal';

// styles
import useStyles from './style';

export default function ProgramSectionWithFilter(props) {
	const { classes } = useStyles();
	const openLoginModal = useUserModal();

	const user = useSelector((state) => state.user.data);
	const isDesktop = useSelector((state) => state.common.isDesktop);

	const paginationClasses = {
		classes: {
			root: classes.paginationRoot,
			toolbar: classes.paginationToolbar,
			selectLabel: classes.paginationSelectLabel,
			displayedRows: classes.paginationDisplayedRows,
			actions: classes.paginationActions,
		},
		SelectProps: {
			className: classes.paginationSelectRoot,
		},
	};

	const handleCardButtonClick = (link) => {
		if (props.requireAuthBeforeRedirect && !(user && user.id)) {
			openLoginModal('login', '', {
				preventRedirect: true,
				redirectionLink: link,
			});
			return;
		}

		goToExternalRoute(link);
	};

	return (
		<Box>
			<Box className={classes.titleSectionWrap}>
				<TitleSection
					title={props.title}
					highlightTitle={props.highlightTitle}
					subTitle={props.subTitle}
					fontSize={props.titleFontSize}
				/>
			</Box>

			<Box className={classes.searchWrap}>
				<SearchFieldGroup
					useIconOnInput={true}
					inputValue={props.searchText}
					inputPlaceholder={textValues.searchCourses}
					handleInputChange={props.handleSearchTextChange}
					useFilters={true}
					filterData={props.filters}
					filterApplied={props.filterApplied}
					handleApplyFilter={props.handleApplyFilter}
					handleClearFilter={props.handleClearFilter}
					filterUiSchema={uiSchema}
					filterControlSchema={controlSchema}
					handleFilterChange={props.handleFilterChange}
					modalOpen={props.modalOpen}
					toggleModalOpen={props.toggleModalOpen}
				/>
			</Box>

			<Box className={classes.programCardListWrap}>
				{props.cardList.length === 0 ? (
					<Typography variant="h6" className={classes.noProgramText}>
						{textValues.noPrograms}
					</Typography>
				) : (
					props.cardList.map((program) => (
						<Box key={program.id} className={classes.programCardWrap}>
							<ProgramCard
								name={program.name}
								logo={program.logo}
								levelAndType={program.levelAndType}
								tuitionFee={program.tuitionFee}
								intakeMonths={program.intakeMonths}
								length={program.length}
								applicationFee={program.applicationFee}
								link={program.link}
								handleButtonClick={handleCardButtonClick}
								buttonLabel={program.buttonLabel}
							/>
						</Box>
					))
				)}
			</Box>
			<TablePagination
				component="div"
				count={props.total}
				page={props.page - 1}
				onPageChange={props.handleChangePage}
				rowsPerPage={props.pageSize}
				rowsPerPageOptions={props.rowsPerPageOptions}
				onRowsPerPageChange={props.handleChangeRowsPerPage}
				labelRowsPerPage={textValues.perPageLabel}
				{...(isDesktop ? {} : paginationClasses)}
			/>
		</Box>
	);
}

ProgramSectionWithFilter.propTypes = {
	// title
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	subTitle: PropTypes.string,
	titleFontSize: PropTypes.string,

	// data
	searchQueryValues: PropTypes.object,
	cardList: PropTypes.array,

	// search text
	searchText: PropTypes.string,
	handleSearchTextChange: PropTypes.func.isRequired,

	// filters
	filterApplied: PropTypes.bool,
	filters: PropTypes.object,
	handleApplyFilter: PropTypes.func.isRequired,
	handleClearFilter: PropTypes.func.isRequired,
	handleFilterChange: PropTypes.func.isRequired,

	// modal
	requireAuthBeforeRedirect: PropTypes.bool,
	modalOpen: PropTypes.bool,
	toggleModalOpen: PropTypes.func.isRequired,

	// pagination
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	rowsPerPageOptions: PropTypes.array.isRequired,
	handleChangePage: PropTypes.func.isRequired,
	handleChangeRowsPerPage: PropTypes.func.isRequired,
};

ProgramSectionWithFilter.defaultProps = {
	requireAuthBeforeRedirect: true,
	modalOpen: false,
	filterApplied: false,
	cardList: [],
	filters: {},
	searchQueryValues: {},
};
