import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// material
import { setFilterToggle } from '@/store/actions';
import { Box, Button } from '@mui/material';

// style
import useStyles from './style';

export default function ToggleGroupedButton(props) {
	const { classes } = useStyles();
	const dispatch = useDispatch();

	const levelFilter = useSelector((state) =>
		get(state, 'filter.toggleValue', null)
	);
	const isMobile = useSelector((state) => state.common.isMobile);

	const [value, setValue] = React.useState(levelFilter || '');

	React.useEffect(() => {
		if (value !== levelFilter) setValue(levelFilter);
	}, [levelFilter]);

	const handleChange = (level) => {
		setValue(level);
		dispatch(setFilterToggle(level));
	};

	return (
		<Box className={classes.buttonGroup}>
			{props.options.map((option, index) => (
				<Button
					key={index}
					className={classes.button}
					size={isMobile ? 'small' : 'medium'}
					onClick={() => handleChange(option.value)}
					variant={value === option.value ? 'contained' : ''}
				>
					{option.label}
				</Button>
			))}
		</Box>
	);
}

ToggleGroupedButton.propTypes = {
	options: PropTypes.array.isRequired,
};
