import Link from 'next/link';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { textValues } from './constants';

// utils
import useUserModal from '@/utils/hooks/use-login-modal';

// components
import TitleSection from '@/components/shared/titleSection';
import ToggleGroupedButton from '@/components/widgets/toggleGroupedButton';
import ProgramCard from '@/modules/unversityPage/programCard';

// material
import { Box } from '@mui/material';

// actions
import { goToExternalRoute, isExternalUrl } from '@/store/actions/navigation';

// styles
import useStyles from './style';

export default function ProgramSectionWithToggle(props) {
	const { classes } = useStyles();
	const user = useSelector((state) => state.user.data);

	const openLoginModal = useUserModal();

	const renderLinkElement = () => {
		if (!props.link) return null;
		const link = props.link;
		if (isExternalUrl(link)) {
			return (
				<a
					href={link}
					target="_blank"
					rel="noreferrer"
					className={classes.link}
				>
					{textValues.linkText}
				</a>
			);
		}
		return (
			<Link href={link}>
				<a className={classes.link}>{textValues.linkText}</a>
			</Link>
		);
	};

	const handleButtonClick = (link) => {
		if (props.requireAuthBeforeRedirect && !(user && user.id)) {
			openLoginModal('login', '', {
				preventRedirect: true,
				redirectionLink: link,
			});
			return;
		}

		goToExternalRoute(link);
	};

	return (
		<Box>
			{props.programLevelFilterOptions.length > 0 && (
				<Box className={classes.buttonGroupWrap}>
					<ToggleGroupedButton options={props.programLevelFilterOptions} />
				</Box>
			)}
			{props.title && (
				<Box className={classes.titleSectionWrap}>
					<TitleSection
						title={props.title}
						highlightTitle={props.highlightTitle}
						subTitle={props.subTitle}
						fontSize={props.titleFontSize}
					/>
				</Box>
			)}

			<Box className={classes.programCardListWrap}>
				{props.cardList.map((program) => (
					<Box key={program.id} className={classes.programCardWrap}>
						<ProgramCard
							name={program.name}
							logo={program.logo}
							levelAndType={program.levelAndType}
							tuitionFee={program.tuitionFee}
							intakeMonths={program.intakeMonths}
							length={program.length}
							applicationFee={program.applicationFee}
							link={program.link}
							handleButtonClick={handleButtonClick}
							buttonLabel={program.buttonLabel}
						/>
					</Box>
				))}
			</Box>

			{props.link && (
				<Box className={classes.linkWrap}>{renderLinkElement()}</Box>
			)}
		</Box>
	);
}

ProgramSectionWithToggle.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	subTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	cardList: PropTypes.array.isRequired,
	programLevelFilterOptions: PropTypes.array,
	link: PropTypes.string,
	requireAuthBeforeRedirect: PropTypes.bool,
};

ProgramSectionWithToggle.defaultProps = {
	programLevelFilterOptions: [],
	requireAuthBeforeRedirect: true,
};
