import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { scroller } from 'react-scroll';

// constants
import { rowsPerPageOptions, textValues, themes } from './constants';

// components
import ProgramSectionWithFilter from './partials/programWithFilterSection';
import ProgramSectionWithToggle from './partials/programWithToggleSection';
import UniversityGroupSection from './partials/universityGroupSection';

// actions
import { setQueriesWithOptions } from '@/store/actions/navigation';
import { universityListAction } from '@/store/actions/pageActions';

// utils
import { Box } from '@mui/material';
import {
	filterProgram,
	getInitialFilter,
	getInitialPerPage,
	getQueryArray,
} from './util';

let timeout = null;

export default function DataListSection({
	searchQueryValues,
	cardList,
	theme,
	...props
}) {
	const dispatch = useDispatch();

	const globalData = useSelector((state) => state.global.data);

	const pendingAPICalls = React.useRef(0);

	// function
	const [modalOpen, setModalOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [scrollToTop, setScrollToTop] = React.useState(false);

	// filters
	const [searchText, setSearchText] = React.useState(
		get(searchQueryValues, 'q', '')
	);
	const [filters, setFilters] = React.useState(() =>
		getInitialFilter(searchQueryValues, theme)
	);
	const [dropdownValue, setDropdownValue] = React.useState(
		filters.country || ''
	);
	const [filterApplied, setFilterApplied] = React.useState(!isEmpty(filters));

	// pagination
	const [total, setTotal] = React.useState(cardList ? cardList.length : 0);
	const [page, setPage] = React.useState(
		parseInt(get(searchQueryValues, 'page', 1))
	);
	const [pageSize, setPageSize] = React.useState(() =>
		getInitialPerPage(searchQueryValues)
	);

	// data
	const [data, setData] = React.useState(
		cardList ? cardList.slice(0, pageSize) : []
	);

	const searchDropdownFilterEnums = (globalData.countries || []).filter(
		(country) => !country.toLowerCase().includes('other')
	);

	const toggleModalOpen = () => setModalOpen(!modalOpen);

	const checkFilterApplied = (filtersData) => {
		return (
			!isEmpty(filtersData.program_level_and_type) ||
			!isEmpty(filtersData.country) ||
			!isEmpty(filtersData.type) ||
			!isEmpty(filtersData.category)
		);
	};

	/**
	 * handler for syncing  search query with values
	 */
	const handleSetQueries = (filters, searchText, page, pageSize) => {
		const query = getQueryArray(filters, searchText, page, pageSize);
		dispatch(setQueriesWithOptions(query, false, false));
	};

	const handleChangePage = (_, newPage) => {
		setPage(newPage + 1);
		setScrollToTop(true);
	};

	const handleChangeRowsPerPage = (event) => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(1);
		setScrollToTop(true);
	};

	const handleSearchTextChange = (value) => {
		if (value === searchText) return;

		setSearchText(value);
		setPage(1);
		setScrollToTop(false);
	};

	const handleFilterChange = function ({ data }) {
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {
			setFilters(data);
		}, 400);
	};

	const handleDropdownChange = (e) => {
		const { value } = e.target;

		if (value === dropdownValue) return;

		const filtersData = { ...filters, country: value };
		setDropdownValue(value);
		setFilters(filtersData);
		setPage(1);
		setFilterApplied(checkFilterApplied(filtersData));
		setScrollToTop(false);
	};

	const handleClearFilter = () => {
		setFilters({});
		setDropdownValue('');
		handleSetQueries({}, searchText, page, pageSize);
		setScrollToTop(false);
		setPage(1);
		setFilterApplied(false);
	};

	const handleApplyFilter = () => {
		if (filters.country !== dropdownValue) setDropdownValue(filters.country);
		handleSetQueries(filters, searchText, page, pageSize);
		setScrollToTop(false);
		setPage(1);
		setFilterApplied(checkFilterApplied(filters));
		toggleModalOpen();
	};

	const applyFilterToPrograms = React.useCallback(
		(filters) => filterProgram(cardList, filters),
		[cardList]
	);

	let SectionComponent, sectionProps, listAction;

	const commonTitleProps = {
		title: props.title,
		highlightTitle: props.highlightTitle,
		subTitle: props.subTitle,
		titleFontSize: props.titleFontSize,
	};

	const commonFilterListProps = {
		cardList: data,
		rowsPerPageOptions,
		searchQueryValues: props.searchQueryValues,
		searchText,
		filterApplied,
		filters,
		modalOpen,
		page,
		pageSize,
		total,
		handleSearchTextChange,
		handleApplyFilter,
		handleClearFilter,
		handleFilterChange,
		toggleModalOpen,
		handleChangePage,
		handleChangeRowsPerPage,
	};

	switch (theme) {
		case themes.v1:
			SectionComponent = ProgramSectionWithToggle;
			sectionProps = {
				...commonTitleProps,
				link: props.link,
				cardList,
				programLevelFilterOptions: props.programLevelFilterOptions,
				requireAuthBeforeRedirect: props.requireAuthBeforeRedirect,
			};
			break;

		case themes.v2:
			listAction = applyFilterToPrograms;
			SectionComponent = ProgramSectionWithFilter;
			sectionProps = {
				...commonTitleProps,
				...commonFilterListProps,
				requireAuthBeforeRedirect: props.requireAuthBeforeRedirect,
			};
			break;

		case themes.v3:
			SectionComponent = UniversityGroupSection;
			listAction = universityListAction;
			sectionProps = {
				...commonFilterListProps,
				dropdownValue,
				handleDropdownChange,
				searchDropdownFilterEnums,
				loading,
			};
			break;
	}

	const debouncedListAction = React.useCallback(
		debounce((filters, scrollToTop = false) => {
			setLoading(true);
			++pendingAPICalls.current;
			if (scrollToTop) scroller.scrollTo(textValues.scrollId, { offset: -80 });

			listAction(filters)
				.then((response) => {
					if (pendingAPICalls.current !== 1) return;

					setTotal(parseInt(response.meta.pagination.total));
					setData(response.data);
				})
				.finally(() => {
					if (pendingAPICalls.current === 1) setLoading(false);
					--pendingAPICalls.current;
				});
		}, 300),
		[]
	);

	// to sync search params with search text and pagination values
	React.useEffect(() => {
		if (theme === themes.v1) return;

		handleSetQueries(filters, searchText, page, pageSize);
	}, [page, pageSize, searchText, dropdownValue]);

	// to sync programs with search params
	React.useEffect(() => {
		if (!listAction) return;

		debouncedListAction(searchQueryValues, scrollToTop);
	}, [searchQueryValues]);

	return (
		<Box id={textValues.scrollId}>
			<SectionComponent {...sectionProps} />
		</Box>
	);
}

DataListSection.propTypes = {
	title: PropTypes.string,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	cardList: PropTypes.array,
	programLevelFilterOptions: PropTypes.array,
	searchQueryValues: PropTypes.object,
	link: PropTypes.string,
	requireAuthBeforeRedirect: PropTypes.bool,
};
