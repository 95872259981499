const uiSchema = {
	type: 'VerticalLayout',
	elements: [
		{
			type: 'Control',
			scope: '#/properties/country',
			label: 'Country',
			options: {
				limitDropdownHeight: true,
			},
		},
		{
			type: 'Control',
			scope: '#/properties/type',
			label: 'University Type',
			options: {
				component: 'multiSelect',
			},
		},
	],
};

export default uiSchema;
