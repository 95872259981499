import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	buttonGroup: {
		backgroundColor: theme.palette.common.white,
		borderRadius: '25px',
		display: 'inline-flex',
		border: '1px solid #B9B6B6',
	},
	button: {
		borderRadius: '25px',
		boxShadow: 'none',
		minWidth: '150px',
		height: '100%',
		padding: '6px 16px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px',
			minWidth: '90px',
		},
	},
	toggleRoot: {
		border: `1px solid ${theme.palette.common.grey}`,
		borderRadius: '20px',
	},
}));

export default useStyles;
